export const STATUS = {
  OPEN: {
    ID: 1,
    NAME: 'OPEN'
  },
  ARCHIVED: {
    ID: 2,
    NAME: 'ARCHIVED'
  },
  PUBLISHED: {
    ID: 3,
    NAME: 'PUBLISHED'
  }
};

export const ROLE = {
  PRIMARY: 'Primary',
  ADMIN: 'Admin',
  BOARD: 'Board',
  EXEC: 'Executive',
  MANAGER: 'Manager'
};

export const FORM_TYPE = {
  DISTRICT: {
    ID: 1,
    NAME: 'Region / Estimating / Corporate',
    SHORT: 'Office'
  },
  OPERATIONS: {
    ID: 2,
    NAME: 'Operations',
    SHORT: 'Ops'
  },
  SERVICES_OPS: {
    ID: 3,
    NAME: 'Services Operations',
    SHORT: 'S. Ops'
  },
  SERVICES_STAFF: {
    ID: 4,
    NAME: 'Services Staff',
    SHORT: 'S. Staff'
  },
  LEGAL: {
    ID: 5,
    NAME: 'Corp Legal',
    SHORT: 'Legal'
  },
  TECHNOLOGY: {
    ID: 6,
    NAME: 'Technology',
    SHORT: 'Tech'
  },
  CRAFT: {
    ID: 7,
    NAME: 'Craft',
    SHORT: 'Craft'
  },
  FOREMAN: {
    ID: 8,
    NAME: 'GF/Foreman',
    SHORT: 'GF/F'
  },
  INTERN: {
    ID: 9,
    NAME: 'Intern',
    SHORT: 'Intern'
  },
  STOCKHOLDERS: {
    ID: 10,
    NAME: 'Stockholders',
    SHORT: 'Stock'
  },
  SERVICES_TECH: {
    ID: 11,
    NAME: 'Services Technician',
    SHORT: 'S. Tech'
  },
  TALENT_ACQUISITION: {
    ID: 12,
    NAME: 'Talent Acquisition',
    SHORT: 'Talent Acq.'
  },
  HUMAN_RESOURCES: {
    ID: 13,
    NAME: 'Human Resources',
    SHORT: 'HR'
  },
  ADMIN_ASST: {
    ID: 14,
    NAME: 'Administrative Assistant',
    SHORT: 'Admin Asst'
  },
  TECHNOLOGY_LEADERS: {
    ID: 15,
    NAME: 'Technology Leaders',
    SHORT: 'Tech Leaders'
  },
  TECHNOLOGY_OLD: {
    ID: 16,
    NAME: 'Technology',
    SHORT: 'Tech'
  },
  BETA_ADMIN_ASST: {
    ID: 17,
    NAME: 'Beta - Administrative Assistant',
    SHORT: 'Beta - Admin Asst'
  },
  BETA_DISTRICT: {
    ID: 18,
    NAME: 'Beta - Region / Estimating / Corporate',
    SHORT: 'Beta - Office'
  },
  BETA_LEGAL: {
    ID: 19,
    NAME: 'Beta - Corp Legal',
    SHORT: 'Beta - Legal'
  },
  BETA_OPERATIONS: {
    ID: 20,
    NAME: 'Beta - Operations',
    SHORT: 'Beta - Ops'
  },
  BETA_SERVICES_OPS: {
    ID: 21,
    NAME: 'Beta - Services Operations',
    SHORT: 'Beta - S. Ops'
  },
  BETA_SERVICES_STAFF: {
    ID: 22,
    NAME: 'Beta - Services Staff',
    SHORT: 'Beta - S. Staff'
  },
  BETA_TECHNOLOGY: {
    ID: 23,
    NAME: 'Beta - Technology',
    SHORT: 'Beta - Tech'
  },
  BETA_TECHNOLOGY_LEADERS: {
    ID: 24,
    NAME: 'Beta - Technology Leaders',
    SHORT: 'Beta - Tech Leaders'
  }
};

export const MODAL = {
  SUBMIT: 'SUBMIT',
  ARCHIVE: 'ARCHIVE',
  CANCEL: 'CANCEL',
  EVAL: 'EVAL',
  GOAL: 'GOAL',
  DISCARD: 'DISCARD',
  DELETE: 'DELETE',
  ERROR: 'ERROR',
  UNSHARE: 'UNSHARE',
  CALENDAR: 'CALENDAR'
};

export const ACTION = {
  SAVE: 'SAVE',
  SEND: 'SEND',
  ARCHIVE: 'ARCHIVE',
  PUBLISH: 'PUBLISH',
  DELETE: 'DELETE',
  SHARE: 'SHARE',
  UNSHARE: 'UNSHARE'
};

export const NAVBAR_ACTION = {
  NEWEVAL: 'New Evaluation',
  EVALDASH: 'Evaluations Dashboard',
  EVALPAGE: 'Evaluation Page',
  GOALDASH: 'Goals Dashboard',
  GOALPAGE: 'New Goal Page',
  NEWGOAL: 'New Goal',
  LOGOUT: 'Logout',
  REPORTS: 'Reports'
};

export const DASHBOARD_VIEWS = {
  ACTIVE: {
    SHORT: 'Active',
    LONG: 'Active Performance Evaluations'
  },
  ARCHIVED: {
    SHORT: 'Archived',
    LONG: 'Archived Performance Evaluations'
  },
  ADMIN: {
    SHORT: 'Administrator',
    LONG: 'Administrator View (Evaluations)'
  },
  BOARD: {
    SHORT: 'Board',
    LONG: 'Board View (Evaluations)'
  },
  EXEC: {
    SHORT: 'Executive',
    LONG: 'Executive View (Evaluations)'
  },
  MANAGER: {
    SHORT: 'Manager',
    LONG: 'Manager View (Evaluations)'
  }
};

export const GOAL_DASH_VIEWS = {
  MY_GOALS: 'My Goals',
  SHARED_GOALS: 'Shared Goals',
  BOARD_VIEW: 'Board View',
  EXECUTIVE_VIEW: 'Executive View',
  MANAGER_VIEW: 'Manager View'
};

export const GOAL_STATUS = {
  NOT_STARTED: { ID: 1, NAME: 'Not Started' },
  IN_PROGRESS: { ID: 2, NAME: 'In Progress' },
  ON_HOLD: { ID: 5, NAME: 'On Hold' },
  COMPLETED: { ID: 4, NAME: 'Completed' },
  CANCELLED: { ID: 3, NAME: 'Cancelled' }
};

export const GOAL_TRACK = {
  NO_FLAG_SET: { ID: 4, NAME: 'No Flag Set' },
  ON_TRACK: { ID: 1, NAME: 'On Track' },
  OFF_TRACK: { ID: 3, NAME: 'Off Track' },
  AT_RISK: { ID: 2, NAME: 'At Risk' }
};

export const GOAL_CATEGORY = {
  NONE: { ID: 9, NAME: 'Select a Category' },
  IMPROVED_PERFORMANCE: { ID: 1, NAME: 'Improved Performance' },
  PROJECT_DEPARTMENT: { ID: 2, NAME: 'Project/Department' },
  PERSONAL_DEVELOPMENT: { ID: 3, NAME: 'Personal Development' },
  PERSONNEL_MGMT: { ID: 4, NAME: 'Personnel Mgmt' },
  ONE_YEAR_OBJECTIVE: { ID: 5, NAME: '1 Year Career Objective' },
  FIVE_YEAR_OBJECTIVE: { ID: 6, NAME: '5 Year Career Objective' },
  TEN_YEAR_OBJECTIVE: { ID: 7, NAME: '10 Year Career Objective' },
  OTHER: { ID: 8, NAME: 'Other' }
};

export const NO_COMPETENCY = { NONE: { ID: 1, NAME: 'Select a Competency' } };

export const FOUNDATIONAL_GOAL_COMPETENCY = {
  ACCOUNTABILITY_OWNERSHIP: { ID: 2, NAME: 'Accountability/Ownership' },
  ADAPTABILITY: { ID: 3, NAME: 'Adaptability' },
  COMMUNICATES_EFFECTIVELY: { ID: 4, NAME: 'Communicates Effectively' },
  INITIATIVE: { ID: 5, NAME: 'Initiative' },
  INTEGRITY: { ID: 6, NAME: 'Integrity' },
  KNOWLEDGE_COMPETENCE: { ID: 7, NAME: 'Job Knowledge/Technical Competence' },
  TEAMWORK_COLLAB: { ID: 8, NAME: 'Teamwork and Collaboration' }
};

export const MID_MGMT_GOAL_COMPETENCY = {
  RELATIONSHIP_COLLAB: { ID: 9, NAME: 'Builds Collaborative Relationships' },
  COACH_DEVELOP: { ID: 10, NAME: 'Coaches and Develops Others' },
  DECISION_MAKING: { ID: 11, NAME: 'Decision Making' },
  EXECUTES_PLAN: { ID: 12, NAME: 'Executes a Plan' },
  LEADS_TEAM: { ID: 13, NAME: 'Leads the Team' }
};

export const UPPER_MGMT_GOAL_COMPETENCY = {
  RELATIONSHIPS_NETWORKS: {
    ID: 14,
    NAME: 'Cultivates Relationships and Networks'
  },
  DELIVERS_EXECUTES: { ID: 15, NAME: 'Delivers Results/Executes' },
  DELIVERS_TALENT: { ID: 16, NAME: 'Develops Talent' },
  EMOTIONAL_INTELLIGENCE: { ID: 17, NAME: 'Emotional Intelligence' },
  MOTIVATES_EMPOWERS: { ID: 18, NAME: 'Motivates and Empowers the Team' }
};

export const EXECUTIVE_GOAL_COMPETENCY = {
  HP_AMBASSADOR: { ID: 19, NAME: 'Ambassador of the Hensel Phelps Culture' },
  BUSINESS_ACUMEN: { ID: 20, NAME: 'Demonstrates Business Acumen' },
  ENTERPRISE_MINDSET: { ID: 21, NAME: 'Enterprise-first Mindset' },
  INSPIRATION_LEADERSHIP: { ID: 22, NAME: 'Inspirational Leadership' },
  MANAGE_COMPLEXITY: { ID: 23, NAME: 'Manages Complexity' }
};

export const competenciesToDisplay = (udCompetency, showNone) => {
  switch (true) {
    case udCompetency === 'Mid':
      if (showNone) {
        return NO_COMPETENCY.NONE.NAME;
      } else {
        return { ...NO_COMPETENCY, ...MID_MGMT_GOAL_COMPETENCY };
      }
    case udCompetency === 'Upper':
      if (showNone) {
        return NO_COMPETENCY.NONE.NAME;
      } else {
        return { ...NO_COMPETENCY, ...UPPER_MGMT_GOAL_COMPETENCY };
      }
    case udCompetency === 'Exec':
      if (showNone) {
        return NO_COMPETENCY.NONE.NAME;
      } else {
        return { ...NO_COMPETENCY, ...EXECUTIVE_GOAL_COMPETENCY };
      }
    case udCompetency === 'Found':
      if (showNone) {
        return NO_COMPETENCY.NONE.NAME;
      } else {
        return { ...NO_COMPETENCY, ...FOUNDATIONAL_GOAL_COMPETENCY };
      }
    default:
      if (showNone) {
        return NO_COMPETENCY.NONE.NAME;
      } else {
        return { ...NO_COMPETENCY, ...FOUNDATIONAL_GOAL_COMPETENCY };
      }
  }
};

export const MAX_COMMENT_CHARS = 10000;

export const NEXT_EVAL_DATE_QUESTION_TEXT = 'Next Evaluation Date';
